import {graphql} from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import {Link} from "gatsby"
import {formatDate} from "utils/type-util";
import Layout from "components/layout"

const IndexWrapper = styled.main``;
const PostWrapper = styled.div``;


export default ({data}) => {

    return (
        <>
            <Layout isVisibleBack={true}>
                <IndexWrapper>
                    {data.allMdx.nodes.map(
                        ({id, excerpt, frontmatter, fields}) => (
                            <PostWrapper key={id}>
                                <Link to={fields.slug}>
                                    <h1>{frontmatter.title}</h1>
                                    <p>{formatDate(frontmatter.date)}</p>
                                    <p>{excerpt}</p>
                                </Link>
                            </PostWrapper>
                        )
                    )}
                </IndexWrapper>

            </Layout>
        </>
    );
};

export const query = graphql`
  query SITE_INDEX_QUERY {
    allMdx(
      sort: { fields: [fields___slug], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
          date
        }
        fields {
          slug
        }
      }
    }
  }
`;